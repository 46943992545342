<template>
    <ValidationObserver ref="observe">
        <b-form>
            <ek-dialog
                ref="addDialog"
                title="إضافة مستخدم"
                size="md"
                placeholder="ابحث عن مستخدم محدد"
                btnText="اضافة مستخدم"
                @ok="submit()"
                @close="reset"
                @search="setSearch"
            >
                <template #body>
                    <ek-input-text
                        :rules="[{ type: 'required', message: 'اسم الطالب مطلوب' }]"
                        name="name"
                        placeholder="أدخل اسم الطالب"
                        label="اسم الطالب  "
                        v-model="studentDto.name"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            { type: 'email', message: 'يرجى ادخال  بريد الكتروني صحيح' },
                        ]"
                        name="email"
                        placeholder="ادخل البريد الإلكتروني "
                        label=" البريد الإلكتروني "
                        v-model="studentDto.email"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            { type: 'mobile', message: 'يرجى ادخال رقم الموبايل صحيح' },
                        ]"
                        name="phoneNumber"
                        placeholder="ادخل رقم الموبايل"
                        label=" رقم الموبايل"
                        v-model="studentDto.phoneNumber"
                    ></ek-input-text>

                    <ek-input-text
                        :rules="[
                            { type: 'required', message: 'كلمة المرور مطلوبة' },
                            { type: 'min:8', message: 'كلمة المرور يجب ان تحوي 8 محارف على الاقل' },
                        ]"
                        name="password"
                        placeholder="أدخل  كلمة السر"
                        label="كلمة السر"
                        v-model="studentDto.password"
                    ></ek-input-text>

                    <div>
                        <label class="pb-1" style="font-size:14px;"> الجنس:</label>
                        <b-form-radio-group
                            v-model="studentDto.gender"
                            :options="options"
                            value-field="item"
                            text-field="name"
                        ></b-form-radio-group>
                    </div>
                </template>
            </ek-dialog>
        </b-form>
    </ValidationObserver>
</template>

<script>

import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";

export default {
   components: {
        ValidationObserver
    },
    data() {
        return {
        options: [
            { item: 1, name: "ذكر" },
            { item: 2, name: "انثى" }
        ],
        };
    },
    computed: {
        ...mapState({
            studentDto: state => state.students.studentDto
        }),
    },
    methods: {
        ...mapActions(["addStudents"]),
        submit() {
            this.$refs.observe.validate().then(success => {
                if (success) {
                    this.addStudents({
                        name: this.studentDto.name,
                        password: this.studentDto.password ,
                        email: this.studentDto.email ,
                        phoneNumber: this.studentDto.phoneNumber,
                        gender: this.studentDto.gender
                    });
                    this.$refs.addDialog.close();
                    this.$refs.observe.reset();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name",'subjectsCount','subCount','isSub', 'phoneNumber'],
                query,
            });
        },
        reset() {
            this.$refs["observe"].reset();
            Object.assign(this.studentDto, {
                name: "",
                password: "",
                phoneNumber: "",
                gender: "",
            });
        },
    }
};
</script>
